class countdown {
  // This executes when the function is instantiated.
  constructor() {
    this.loadCountdowns()
  }

  loadCountdowns() {
    document.querySelectorAll('.js--countdown').forEach(element => this.launch(element));
  }

  launch(item) {
    var countDownDate = new Date(item.dataset.endAt).getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {

      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days    = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours   = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      // Display the result in the element with id="demo"
      if(document.documentElement.lang == 'en')
        item.innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      else if(document.documentElement.lang == 'cn') {
        item.innerHTML = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
      } else {
        item.innerHTML = days + "j " + hours + "h " + minutes + "m " + seconds + "s ";
      }

      // Force page reload 60 seconds and 30 seconds before the end of the countdown and also when the countdown come to 0
      if (days == 0 && hours == 0 && ((minutes == 1 && seconds == 0) || (minutes == 0 && seconds == 30) || (minutes == 0 && seconds == 0))) {
        window.location.reload(true);
      }

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        item.innerHTML = item.dataset.saleClosed;
      }
    }, 1000);
  }
}

window.addEventListener("DOMContentLoaded", (event) => new countdown())
