import 'core-js/stable'
import 'regenerator-runtime/runtime'
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")
var Trix = require("trix")
require("@rails/actiontext")

require('./nested-forms/addFields')
require('./nested-forms/removeFields')
require('./trix_editor')
require('moment/locale/ja')
require('tempusdominus-bootstrap-4')
require('./countdown')
require('./pigeon_form')

import '../src/global.scss'
import '../src/front.scss'
import '../src/admin.scss'
import '../src/devise.scss'
import '../../assets/stylesheets/application.scss'
import '../../assets/stylesheets/actiontext.scss'
import "@fortawesome/fontawesome-free/js/all";

const images = require.context('../images', true)

window.addEventListener("DOMContentLoaded", function() {
  $('[data-toggle="tooltip"]').tooltip();
});

// font style
Trix.config.textAttributes.underline = {
  tagName: 'u'
};

// font kind
Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
};
Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
};

// font color
Trix.config.textAttributes.red = {
  style: { color: "red" },
  parser: function(element) {
      return element.style.color === "red"
  },
  inheritable: true
}
Trix.config.textAttributes.blue = {
  style: { color: "blue" },
  parser: function(element) {
      return element.style.color === "blue"
  },
  inheritable: true
}
Trix.config.textAttributes.green = {
  style: { color: "green" },
  parser: function(element) {
      return element.style.color === "green"
  },
  inheritable: true
}


Trix.config.toolbar = {
  getDefaultHTML() { return `\
<div class="trix-button-row">
  <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" title="#{lang.bold}" tabindex="-1">#{lang.bold}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" title="#{lang.italic}" tabindex="-1">#{lang.italic}</button>
    <button type="button" class="trix-button custom-underline" data-trix-attribute="underline" title="#{lang.underline}" tabindex="-1"></button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" title="#{lang.link}" tabindex="-1">#{lang.link}</button>
  </span>
  <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
    <button type="button" class="trix-button custom-color-red" data-trix-attribute="red" title="#{lang.red}" tabindex="-1"></button>
    <button type="button" class="trix-button custom-color-blue" data-trix-attribute="blue" title="#{lang.blue}" tabindex="-1"></button>
    <button type="button" class="trix-button custom-color-green" data-trix-attribute="green" title="#{lang.green}" tabindex="-1"></button>
  </span>
  <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
    <button type="button" class="trix-button custom-h1" data-trix-attribute="heading1" title="#{lang.heading1}" tabindex="-1"></button>
    <button type="button" class="trix-button custom-h2" data-trix-attribute="heading2" title="#{lang.heading2}" tabindex="-1"></button>
    <button type="button" class="trix-button custom-h3" data-trix-attribute="heading3" title="#{lang.heading3}" tabindex="-1"></button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" title="#{lang.quote}" tabindex="-1">#{lang.quote}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" title="#{lang.code}" tabindex="-1">#{lang.code}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" title="#{lang.bullets}" tabindex="-1">#{lang.bullets}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" title="#{lang.numbers}" tabindex="-1">#{lang.numbers}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" title="#{lang.outdent}" tabindex="-1">#{lang.outdent}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" title="#{lang.indent}" tabindex="-1">#{lang.indent}</button>
  </span>
  <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="#{lang.attachFiles}" tabindex="-1">#{lang.attachFiles}</button>
  </span>
  <span class="trix-button-group-spacer"></span>
  <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" title="#{lang.undo}" tabindex="-1">#{lang.undo}</button>
    <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" title="#{lang.redo}" tabindex="-1">#{lang.redo}</button>
  </span>
</div>
<div class="trix-dialogs" data-trix-dialogs>
  <div class="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
    <div class="trix-dialog__link-fields">
      <input type="url" name="href" class="trix-input trix-input--dialog" placeholder="#{lang.urlPlaceholder}" aria-label="#{lang.url}" required data-trix-input>
      <div class="trix-button-group">
        <input type="button" class="trix-button trix-button--dialog" value="#{lang.link}" data-trix-method="setAttribute">
        <input type="button" class="trix-button trix-button--dialog" value="#{lang.unlink}" data-trix-method="removeAttribute">
      </div>
    </div>
  </div>
</div>\
`; }
};
