class pigeonForm {

  constructor() {
    this.load()
  }

  load() {
    let selectField = document.getElementById('pigeon_general_printing');

    if(selectField) {
      if(selectField.value == 'other') {
        $('#js-general-printing-details').addClass('visible');
      }

      $(selectField).on('change', function(e) {
        if($(this).val() == 'other') {
          $('#js-general-printing-details').addClass('visible');
        } else {
          $('#js-general-printing-details').removeClass('visible');
        }
      });
    }
  }
}

window.addEventListener("DOMContentLoaded", (event) => new pigeonForm())
