class loadTrixeditorFields {

  constructor() {
    this.setHeight()
  }

  setHeight() {
    document.querySelectorAll('.trix-content').forEach(element => element.style.minHeight = "250px");
  }
}

window.addEventListener("DOMContentLoaded", (event) => new loadTrixeditorFields())
